<template>
  <div>
    <a-card title="出库单创建">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <section>
        <a-spin :spinning="loading">
          <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
            <a-row>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="number" label="出库单号">
                  <a @click="jumpStockOutOrderDetail">{{ info.number }}</a>
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="type_display" label="出库类型">
                  {{ info.type_display }}
                </a-form-model-item>
              </a-col>
              <a-col v-if="info.type === 'sales'" :span="6" style="width: 320px">
                <a-form-model-item label="销售单据">
                  <a @click="jumpSalesOrderDetail">{{ info.sales_order_number }}</a>
                </a-form-model-item>
              </a-col>
              <a-col v-if="info.type === 'purchase_return'" :span="6" style="width: 320px">
                <a-form-model-item label="采购退货单据">
                  <a @click="jumpPurchaseReturnOrderDetail">{{ info.purchase_return_order_number }}</a>
                </a-form-model-item>
              </a-col>
              <a-col v-if="info.type === 'stock_transfer'" :span="6" style="width: 320px">
                <a-form-model-item label="调拨单据">
                  <a @click="jumpStockTransferOrderDetail">{{ info.stock_transfer_order_number }}</a>
                </a-form-model-item>
              </a-col>
              <a-col v-if="info.type === 'production_stock_out'" :span="6" style="width: 320px">
                <a-form-model-item label="生产工单">
                  <a @click="jumpProductionOrderDetail">{{ info.production_order_number }}</a>
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="handler" label="制单人">
                  <a-select v-model="form.handler" style="width: 100%">
                    <a-select-option v-for="item in handlerItems" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="handle_time" label="制单日期">
                  <a-date-picker v-model="form.handle_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="remark" label="备注">
                  <a-input v-model="form.remark" allowClear />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-table rowKey="id" size="small" :columns="columns" :data-source="goodsData" :pagination="false">
              <div slot="stock_out_quantity" slot-scope="value, item, index">
                <div v-if="item.isTotal">{{ value }}</div>
                <a-input-number v-else v-model="item.stock_out_quantity" :min="0" size="small"></a-input-number>
              </div>
              <div slot="batch" slot-scope="value, item, index">
                <span v-if="!item.isTotal && item.batch_items.length === 0">无库存</span>
                <a-select
                  v-if="!item.isTotal && item.batch_items.length > 0"
                  v-model="item.batch"
                  size="small"
                  style="width: 100%"
                >
                  <a-select-option v-for="batch in item.batch_items" :key="batch.id" :value="batch.id">
                    {{ batch.warehouse_name }} | {{ batch.number }} | 库存: {{ batch.remain_quantity }}
                  </a-select-option>
                </a-select>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="danger" @click="removeProduct(index)">移除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </a-form-model>
        </a-spin>
        <div style="margin-top: 32px">
          <a-popconfirm title="确定创建吗?" @confirm="create">
            <a-button type="primary" :loading="loading">创建</a-button>
          </a-popconfirm>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
import { userOption } from "@/api/option";
import { stockOutOrderDetail, stockOutCreate } from "@/api/warehouse";
import NP from "number-precision";
import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      info: {},
      form: { handler: undefined, handle_time: undefined },
      rules: {
        handler: [{ required: true, message: "请选择经手人", trigger: "change" }],
        handle_time: [{ required: true, message: "请选择处理日期", trigger: "change" }],
      },
      materialItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "产品名称",
          dataIndex: "goods_name",
          key: "goods_name",
          width: 150,
        },
        {
          title: "产品编号",
          dataIndex: "goods_number",
          key: "goods_number",
          width: 150,
        },
        {
          title: "应出库数量",
          dataIndex: "total_quantity",
          width: 120,
        },
        {
          title: "待出库数量",
          dataIndex: "remain_quantity",
          width: 120,
        },
        {
          title: "出库数量",
          dataIndex: "stock_out_quantity",
          key: "stock_out_quantity",
          width: 120,
          scopedSlots: { customRender: "stock_out_quantity" },
        },
        {
          title: "推荐仓库",
          dataIndex: "batch",
          key: "batch",
          width: 200,
          scopedSlots: { customRender: "batch" },
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 80,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  created() {},
  computed: {
    goodsData() {
      // 统计合计
      let totalQuantity = 0,
        totalAmount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.stock_out_quantity);
      }
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          stock_out_quantity: totalQuantity,
        },
      ];
    },
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    async initData() {
      userOption({ page_size: 999999, is_active: true }).then((data) => {
        this.handlerItems = data.results;
      });

      stockOutOrderDetail({ id: this.$route.query.id })
        .then(async (data) => {
          this.info = data;
          const productionItems = data.stock_out_goods_items.map((item) => ({ ...item, batch: undefined }));
          for (const item of productionItems) {
            item.total_quantity = item.stock_out_quantity;
            item.stock_out_quantity = item.remain_quantity;

            // 推荐批次
            item.batch_items.sort((a, b) => a.create_time.localeCompare(b.create_time));
            if (item.batch_items.length > 0) {
              item.batch = item.batch_items[0]["id"];
            }
          }

          this.materialItems = productionItems;
        })
        .finally(() => {
          this.loading = false;
        });

      this.form.handler = this.userId;
      this.form.handle_time = moment().format("YYYY-MM-DD");
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let ifHasEmptyBatch = false;
          let ifHasEmptyAmount = false;
          this.materialItems.map((item) => {
            if (item.enable_batch_control && !item.batch) {
              ifHasEmptyBatch = true;
            }
            if (!item.stock_out_quantity) {
              ifHasEmptyAmount = true;
            }
          });
          if (ifHasEmptyAmount) {
            this.$message.warn("请输入出库数量");
            return false;
          }
          if (ifHasEmptyBatch) {
            this.$message.warn("请选择仓库再提交");
            return false;
          }

          if (this.materialItems.length === 0) {
            this.$message.warn("无出库产品");
            return false;
          }
          
          // this.loading = true;
          let formData = {
            ...this.form,
            stock_out_order: this.info.id,
            stock_out_record_goods_items: this.materialItems.map((item) => {
              return {
                stock_out_goods: item.id,
                stock_out_quantity: item.stock_out_quantity,
                batch: item.batch,
              };
            }),
          };
          stockOutCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.push({ path: "/warehouse/outStock" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    removeProduct(index) {
      this.materialItems.splice(index, 1);
    },
    jumpStockOutOrderDetail() {
      this.$router.push({ path: "/warehouse/outStock_detail", query: { id: this.info.id } });
    },
    jumpSalesOrderDetail() {
      this.$router.push({ path: "/sale/sale_record_detail", query: { id: this.info.sales_order } });
    },
    jumpPurchaseReturnOrderDetail() {
      this.$router.push({ path: "/purchasing/return_record_detail", query: { id: this.info.purchase_return_order } });
    },
    jumpStockTransferOrderDetail() {
      this.$router.push({ path: "/warehouse/allocationDetail_detail", query: { id: this.info.stock_transfer_order } });
    },
    jumpProductionOrderDetail() {
      this.$router.push({ path: "/production/detial", query: { id: this.info.production_order } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
